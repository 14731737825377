<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Grading Evaluation</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            
            <div class="mb-4">
             <!-- advance serach -->
             <div class="d-flex">
                <div class="d-flex flex-column me-2 mb-3">
                    <label class="col-form-label">Program</label>
                    <div style="width: 250px">
                        <v-select label="name" v-model="program" :options="programs" :clearable="false"
                            :selectable="(options) => program.short_name != options.short_name "
                            @option:selected="findBatch_programFilter(program)">
                        </v-select>
                    </div>
                </div>
                <div class="d-flex flex-column me-2">
                    <label class="col-form-label">Batch</label>
                    <div style="width: 180px">
                        <v-select label="batch_no" v-model="batch" :options="batchs" :clearable="false"
                        :selectable="(options) =>  batch.id != options.id "
                        @option:selected="FilterData(program, batch)"
                        >
                        </v-select>
                    </div>
                </div>
                <div class="d-flex mt-4">
                    <!-- <div class="col-form-label d-none">hfhd</div> -->
                    <div @click="refreshData()" class="icon-css">
                        <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                    </div>
                </div> 
            </div>  
                <!--end advance serach -->

            <div class="d-flex justify-content-end">
                  <router-link :to="{ name: 'grading-evaluation-management-create' }">
                    <button
                      v-show="!loading"
                      type="button"
                      class="btn btn-sm btn-blue waves-effect waves-light"
                      v-if="hasPermissions(['grading-evaluation-create'])"
                    >
                      <i class="mdi mdi-plus-circle"></i> Add Record
                    </button>
                  </router-link>
              </div>
            </div>    
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive" v-if="!loading">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="grading-evaluation"
                v-if="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Employee Name</th>
                    <th>Program Name</th>
                    <th>Batch</th>
                    <th>Inclass</th>
                    <th>OJT</th>
                    <th>Total Score</th>
                    <th>Grade</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <template
                    v-for="(item, index) in gradingEvaluation"
                    :key="index"
                  >
                    <tr>
                      <td>{{ ++index }}</td>
                      <td>{{ item.employee.name }}</td>
                      <td>{{ item.program.title }}</td>
                      <td>{{ item.batch.batch_no }}</td>
                      <td>{{ item.inclass_percentage }}</td>
                      <td>{{ item.ojt_percentage }}</td>
                      <td>{{ item.inclass_percentage + item.ojt_percentage + parseInt(item.officer_score) }}</td>
                      <td>{{ item.grade }}</td>
                      <td>
                        <span>
                          <router-link
                            class="action-icon"
                            :to="{
                              name: 'grading-evaluation-management-update',
                              params: { id: item.id },
                            }"
                          >
                            <i class="mdi mdi-square-edit-outline"></i>
                          </router-link>
                        </span>
                      </td>

                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Program Allocation
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
// import { HTTP } from "../../../service/http-common";

//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
 
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();

    return { toast };
  },
  components:{
    vSelect
  },
  data() {
    return {
      gradingEvaluation: [],
      // eslint-disable-next-line no-undef
      // Http: HTTP,
      programs: this.$store.getters["odoo/getAllPrograms"],
      batchs : [],
      program : "",
      batch : "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {

    async getAllGradingEvaluation() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v2/grading-evaluation`)
        .then((response) => {
          this.gradingEvaluation = response.data.data;
          this.loading = false;
          this.$Progress.finish();
          // console.log(this.assessment);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#grading-evaluation").DataTable();
    },

    refreshData() {
      this.clearFilter();
      this.getAllGradingEvaluation();
    },

    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#grading-evaluation").DataTable().draw();
    },
    async findBatch_programFilter(program) {
            this.batch = "";
            this.loading = true;
            await axios
                .get(`${this.baseUrl}admin/v1/program-batches/list/?program_id=${program.id}`)
                .then((response) => {
                    this.batchs = response.data.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.toast.error("Not Found Batch!");
                });
    
                this.clearFilter();
                $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
                    const check_program = program?.name == data[2] ? true : false;
                    return check_program ? 1 : 0;
                });
                $("#grading-evaluation").DataTable().draw(); 
        },
    FilterData(program, batch) {
            this.clearFilter();
            // alert('reach');
            $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
                const check_program = program?.name == data[2] ? true : false;
                const check_batch = batch?.batch_no == data[3] ? true : false;
                
                return (check_program && check_batch) ? true : false;
            });
            $("#grading-evaluation").DataTable().draw(); 
        },
    refreshData() {
      this.gradingEvaluation = [];
      this.batchs = [];
      this.batch = "";
      this.program = "";
      this.clearFilter();
      this.getAllGradingEvaluation();
    },
  },
  created() {
    this.clearFilter();
    this.getAllGradingEvaluation();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}

</style>
